body {
    margin: 0px;
    padding: 0px;
    font-family: Roboto;
}
h1, h2, h3 {
    font-weight: bold;
}
.menu {
    color: white;
    text-decoration: none;
}
.content {
    width: 83vw;
}
.user {
    padding: 5px;
    margin-right: 20px;
    border-radius: 50%;
    border: 2px solid #3E3B4E;
}
.topMenu hr {
    padding: 0px;
    margin: 10px 0px;
}
p {
    color: gray;
    font-size: 15px;
}
.card {
    margin: 30px;
    text-align: left;
    padding-left: 20px;
    border-radius: 20px;
    background-color: #3E3B4E;
}
.lang {
    font-size: 14px;
}
.bottom {
    position: absolute;
    bottom: 0;
}
.card .people {
    margin-top: -15px;
}
.card1 {
    width: 95%;
    text-align: left;
    border-radius: 20px;
    margin: 25px 5px 25px 25px;
    padding: 30px 20px 40px 20px;
    border: solid 1px #D8D8D8;
    background-color: #F4F4F4;
}
.card2 {
    width: 95%;
    text-align: left;
    border-radius: 20px;
    margin: 25px 25px 25px 5px;
    background-color: #F4F4F4;
    border: solid 1px #D8D8D8;
    padding: 30px 20px 40px 20px;
}
.card1 ul, .card2 ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}
.card1 ul li, .card2 ul li {
    float: left;
    width: 50%;
    line-height: 1;
}

.card1 ul li h6, .card2 ul li h6 {
    color: grey;
}
.card1 table, .card2 table {
    width: 100%;
    margin-top: 60px;
}
.left {
    text-align: left;
}
.left span {
    font-size: 15px;
    font-weight: bold;
}
.left label {
    font-size: 13px;
    margin-top: -25px;
}
.right {
    text-align: right;
}
.danger {
    border: none;
    color: white;
    font-size: 12px;
    border-radius: 100px;
    background-color: #F65042;
    padding: 10px 30px 10px 30px;
}
.success {
    border: none;
    color: white;
    font-size: 12px;
    border-radius: 100px;
    background-color: #27E34B;
    padding: 10px 30px 10px 30px;
}
.footer {
    padding: 0px;
    margin-top: 220px;
}
.loader {
    display:flex;
    align-items:center;
    justify-content:center;
}