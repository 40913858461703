.topMenu {
    margin: 0px;
    height: 100px;
    text-align: right;
    list-style-type: none;
}
.topMenuContainer{
    display:flex;
    align-items: center;
    justify-content: center;
}
.langContainer {
    width: 100px;
    display:flex;
    margin-top: 12px;
    margin-left: 10px;
    align-items: center;
    justify-content: space-around;
}
.user {
    border: 2px solid #3E3B4E;
    padding: 5px;
    border-radius: 50%;
}