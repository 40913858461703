body {
    margin: 0px;
    padding: 0px;
    font-family: Roboto;
}

h1, h2, h3 {
    font-weight: bold;
}

.header {
    background-color: #3E3B4E;
    margin: 0px;
    padding: 20px 0px;

}

.header ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 30px 0px 5px 0px;
    overflow: hidden;
}

.header ul li {
    width: 100%;
    margin: 0px;
    padding: 14px 5px 0px 15px;
}

.header ul li.active {
    width: 100%;
    background-color: #E8DBC3;
    padding: 14px 5px 0px 15px;
    border-right: 5px solid #F6BA42;
}

.header ul li.active .menu {
    color: #3E3B4E;
}

.header ul li.active .menu svg path {
    fill: #3E3B4E;
}

.header ul li:hover .menu {
    color: #3E3B4E;
}

.header ul li:hover {
    width: 100%;
    background-color: #E8DBC3;
    padding: 14px 5px 0px 15px;
    border-right: 5px solid #F6BA42;
}

.header ul li:hover .menu {
    color: #3E3B4E;
}

.header ul li:hover .menu svg path {
    fill: #3E3B4E;
}

.menu {
    text-decoration: none;
    color: white;
}

.content {
    margin-left: 260px;
    width: 100%;
}

.topMenu {
    list-style-type: none;
    text-align: right;
    margin: 0px;
    height: 100px;
}

.user {
     border: 2px solid #3E3B4E;
     padding: 5px;
     margin-right: 20px;
     border-radius: 50%;
}

/* .topMenu .dot {
    text-align: left;
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
} */

.topMenu hr {
    padding: 0px;
    margin: 10px 0px;
}

p {
    color: gray;
    font-size: 15px;
}

.card {
    background-color: #3E3B4E;
    margin: 30px;
    padding-left: 20px;
    border-radius: 20px;
    text-align: left;
}

.card .people {
    margin-top: -15px;
}

.logo {
    width: 50px;
    margin-left: 25px;
}

.card1 {
    background-color: #F4F4F4;
    width: 95%;
    margin: 25px 5px 25px 25px;
    padding: 30px 20px 40px 20px;
    border: solid 1px #D8D8D8;
    border-radius: 20px;
    text-align: left;
}

.card1 table, .card2 table {
    width: 100%;
}

.left {
    text-align: left;
    padding: 10px 0px;
}

.lang {
    font-size: 14px;
}

.left span {
    font-weight: bold;
    font-size: 15px;
}

.left label {
    margin-top: -25px;
    font-size: 13px;
}

.right {
    text-align: right;
}

.danger {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: #F65042;
    border: none;
    color: white;
    font-size: 12px;
}


.delete {
    padding: 5px 35px 5px 35px;
    border-radius: 100px;
    background-color: rgb(238, 67, 67);
    border: none;
    color: white;
    font-size: 14px;
}

.success {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: #27E34B;
    border: none;
    color: white;
    font-size: 12px;
}

.sendCode {
    padding: 15px 50px 15px 50px;
    border-radius: 100px;
    background-color: #3E3B4E;
    border: none;
    color: white;
    font-size: 16px;
}

.invite {
    padding: 12px 70px 12px 70px;
    border-radius: 100px;
    background-color: #3E3B4E;
    border: none;
    color: white;
    font-size: 16px;
}

.form {
    text-align: left;
    margin: 20px 50px;
}

.form input, .form select {
    margin: 10px 0px 20px 0px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #363636;
    border-radius: 0px;
}

.form div button {
    width: 50%;
    border-radius: 50px;
    font-size: 18px;
}


.footer {
    margin-top: 220px;
    padding: 0px;
}
.card0 {
    background-color: #3E3B4E;
    margin: 30px;
    padding: 20px 30px;
    border-radius: 20px;
    text-align: left;
}

.gameTitle {
    color: #F6BA42;
    font-weight: bold;
}

.description {
    color: #fff !important;
}

.logo {
    width: 50px;
    margin-left: 25px;
}
.card2 {
    background-color: #F4F4F4;
    margin: -0px 30px 50px 30px;
    padding: 50px 30px;
    border: solid 1px #D8D8D8;
    border-radius: 20px;
    text-align: left;
}

.card1 ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.card1 ul li {
    float: left;
    width: 50%;
    line-height: 1;
}

.card1 ul li h6 {
    color: grey;
}

.card1 table {
    width: 100%;
    margin-top: 20px;
}

.left {
    text-align: left;
}

.left span {
    font-weight: bold;
    font-size: 15px;
}

.left label {
    margin-top: -25px;
    font-size: 13px;
}

.right {
    text-align: right;
}

.name,
.name:hover {
    font-size: 18px;
    color: #fff;
    background-color: #F65042;
    padding: 20px 30px;
    margin: 0px 0px -10px 40px;
    border-radius: 20px;
    border: none;
    text-decoration: none;
}

.nameActive,
.nameActive:hover {
    font-size: 18px;
    color: #000;
    background-color: #c8c8c8;
    padding: 20px 30px;
    margin: 0px 0px -10px 40px;
    border-radius: 20px;
    border: none;
    text-decoration: none;
}

.small {
    font-size: 12px;
    color: #000;
    background-color: #fff;
    text-align: right;
    margin-left: 50px;
    padding: 5px 10px;
    border-radius: 20px;
}