body {
    margin: 0px;
    padding: 0px;
    font-family: Roboto;
}

h1, h2, h3 {
    font-weight: bold;
}

.header {
}
/* 
.header ul {
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 30px 0px 5px 0px;
    overflow: hidden;
}

.header ul li {
    width: 100%;
    margin: 0px;
    padding: 14px 5px 0px 15px;
}

.header ul li.active {
    width: 100%;
    background-color: #E8DBC3;
    padding: 14px 5px 0px 15px;
    border-right: 5px solid #F6BA42;
}

.header ul li.active .menu {
    color: #3E3B4E;
}

.header ul li.active .menu svg path {
    fill: #3E3B4E;
}

.header ul li:hover .menu {
    color: #3E3B4E;
}

.header ul li:hover {
    width: 100%;
    background-color: #E8DBC3;
    padding: 14px 5px 0px 15px;
    border-right: 5px solid #F6BA42;
}

.header ul li:hover .menu {
    color: #3E3B4E;
}

.header ul li:hover .menu svg path {
    fill: #3E3B4E;
} */

.menu {
    text-decoration: none;
    color: white;
}

.content {
    margin-left: 260px;
    width: 100%;
}


.topMenu {
    list-style-type: none;
    text-align: right;
    margin: 0px;
    height: 100px;
}

.user {
     border: 2px solid #3E3B4E;
     padding: 5px;
     margin-right: 20px;
     border-radius: 50%;
}

/* .topMenu .dot {
    text-align: left;
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
} */

.topMenu hr {
    padding: 0px;
    margin: 10px 0px;
}

p {
    color: gray;
    font-size: 15px;
}

.type {
    background-color: #3E3B4E;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    margin-left: 20px;
    border-radius: 20px;
}

.card {
    background-color: #3E3B4E;
    margin: 30px;
    padding-left: 20px;
    border-radius: 20px;
    text-align: left;
}

.lang {
    font-size: 14px;
}

.card .people {
    margin-top: -15px;
}

.logo {
    width: 50px;
    margin-left: 25px;
}

.card1 {
    background-color: #F4F4F4;
    width: 100%;
    margin: 25px 5px 25px 25px;
    padding: 30px 20px 40px 20px;
    border: solid 1px #D8D8D8;
    border-radius: 20px;
    text-align: left;
}

.card1 ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.card1 ul li {
    float: left;
    width: 50%;
    line-height: 1;
}

.card1 ul li h6{
    color: grey;
}

.card1 table {
    width: 100%;
}

.card1 table td {
    padding: 10px 0px;
    border-bottom: 1px solid grey;
}

.left {
    text-align: left;
}

.left span {
    font-weight: bold;
    font-size: 15px;
}

.left label {
    margin-top: -25px;
    font-size: 13px;
}

.right {
    text-align: right;
}

.danger {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: #F65042;
    border: none;
    color: white;
    font-size: 12px;
}

.success {
    padding: 10px 30px 10px 30px;
    border-radius: 100px;
    background-color: #27E34B;
    border: none;
    color: white;
    font-size: 12px;
}

.generatedCode {
    border: #F6BA42 1px solid;
    border-radius: 20px;
    letter-spacing: 0.7cm;
    padding: 10px 20px 10px 40px;
    font-size: 50px;
}

.sendCode {
    padding: 15px 100px 15px 100px;
    border-radius: 100px;
    background-color: #3E3B4E;
    border: none;
    color: white;
    font-size: 16px;
}

.footer {
    margin-top: 220px;
    padding: 0px;
}
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggle {
    background-color: transparent !important;
    color: #E8DBC3 !important;
    box-shadow: none !important;
    padding: 10px 60px 10px 15px;
    text-align: left !important;
    border: none !important;
    border-radius: 0px !important;
}
.toggle svg path {
    fill: #E8DBC3;
}
.toggle:hover, .toggle:focus, .toggle:active, .toggle:checked {
    background-color: #E8DBC3 !important;
    outline: 0 !important;
    color: #3E3B4E !important;
    box-shadow: none !important;
    padding: 10px 60px 10px 15px;
    text-align: left !important;
    border: none !important;
    border-right: #F6BA42 5px solid !important;
    border-radius: 0px !important;
}
.toggle:hover svg path, .toggle:focus svg path, .toggle:active svg path, .toggle:checked svg path {
    fill: #3E3B4E;
}
.menu {
    background-color: transparent !important;
    border: none !important;
    margin-left: -15px;
    padding: 10px 40px 10px 15px;
}
.item {
    background-color: transparent !important;
    color: #E8DBC3;
    border: none !important;
    padding: 10px 30px 10px 60px;
    border-radius: 0px !important;
}
.item svg path {
    fill: #E8DBC3;
}
.item:hover svg path, .item:focus svg path, .item:active svg path {
    fill: #3E3B4E;
}
.item:hover, .item:focus, .item:active {
    background-color: #E8DBC3 !important;
    color: #3E3B4E;
    border: none !important;
    padding: 10px 30px 10px 60px;
    border-right: #F6BA42 5px solid !important;
    border-radius: 0px !important;
}

.leftTab {
    display: inline-block;
    position: fixed;
    background-color: #3E3B4E;
    padding-top: 20px;
    width: 17%;
}

.rightTab {
    display: inline-block;
    background-color: blue;
    width: 83%;
}