.container {
    display: flex;
    user-select: none;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .label {
    order: 0;
    flex: none;
    flex-grow: 0;
    font-size: 16px;
    margin: 4px 0px;
    color: #1b1b1b;
    line-height: 25px;
  }
  .error {
      font-size: 12px;
      font-weight: 600;
      color: #ff0000;
      margin-bottom: 5px;
  }
  .field {
    border: none;
    width: 330px;
    height: 40px;
    outline: none;
    transition: 0.1s;
  }
  .fieldContainer {
      display: flex;
      position: relative;
      align-items: flex-end;
  }
  .icon {
      right: 0;
      cursor: pointer;
      position: absolute;
      margin-bottom: 5px;
  }
  .labelContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }