.container {
    color: white;
    height: 100vh;
    user-select: none;
    background-color: #3E3B4E;
}
.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3E3B4E;
}
.footerContainer {
    background-color: #3E3B4E;
}
.logo {
    width: 50px;
    margin: 20px 0;
}
.navItemsContainer {
    margin: 0;
    padding: 30px 0px 5px;
}
.active {
    color: #3E3B4E;
    margin-left: -25px;
    padding-left: 40px;
    background-color: #E8DBC3;
    border-right: 5px solid #F6BA42;
}
.inactive:hover, .active:hover {
    background-color: #606060;
    border-right: 5px solid #F6BA42;
}
.inactive {
    margin-left: -25px;
    padding-left: 40px;
    background-color: #3E3B4E
}
.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: red !important;
}