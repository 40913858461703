body {
    margin: 0px;
    padding: 0px;
    font-family: Roboto;
}

h1, h2, h3 {
    font-weight: bold;
}
.subtitle {
    color: #3E3B4E;
    margin: 0px 0px 30px 0px;
}
p {
    color: gray;
    font-size: 14px;
}
.card {
    background-color: #f6ba42;
    padding: 25px;
    height: 100%;
}
.image1 {
    width: 60vw;
    height: 63vh;
    margin-bottom: 10px;
}
.logo {
    width: 100px;
    margin: 50px 0px 50px 0px;
}
.content {
    padding-top: 15%;
    margin: 0% 10%;
    height: 90%;
}
.right {
    height: 100%;
    text-align: center;
    background-color: #f6ba42;
}
.footer {
}
.select {
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0px 50px 20px 50px;
}
.getStartedButton {
    margin-right: 10px;
}