.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.menu {
    color: white;
    text-decoration: none;
}
.menu span {
    margin-left: 10px;
}
.dropdownItem {
    display: flex;
    flex-direction: column;
}
.active {
    background-color: #E8DBC3;
    border-right: 5px solid #F6BA42;
}
.active .menu {
    color: #3E3B4E;
}
.active .menu svg path {
    fill: #3E3B4E;
}
.container li {
    margin: 0px;
    padding: 14px 5px 0px 15px;
}
.container li:hover .menu {
    color: #3E3B4E;
}
.container li:hover {
    width: 100%;
    padding: 14px 5px 0px 15px;
    background-color: #E8DBC3;
    border-right: 5px solid #F6BA42;
}
.container li:hover .menu {
    color: #3E3B4E; 
}
.container li:hover .menu svg path {
    fill: #3E3B4E;
}
.dropdownItemsContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
p {
    color: gray;
    font-size: 15px;
}
