body {
    margin: 0px;
    padding: 0px;
    font-family: Roboto;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

h1, h2, h3 {
    font-weight: bold;
    color: #363636;
}

.subtitle {
    color: #3E3B4E;
    margin: 0px 0px 30px 0px;
}

p {
    color: gray;
    font-size: 14px;
}

.lang {
    color: gray;
    font-size: 14px;
}

label {
    color: gray;
    font-size: 16px;
}

header ul {
    list-style-type: none;
    width: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

header ul li {
    float: left;
    text-align: left;
    width: 50vw;
}

.lang {
    padding: 30px;
    float: right;
}

.content {
    margin: 0% 15%;
}

.content1 {
    margin: 15% 10% 14% 10%;
}

.form {
    text-align: left;
}

.form input, .input {
    width: 100%;
    margin: 5px 0px 10px 0px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #363636;
    border-radius: 0px;
}

.form input:focus, .input:focus {
    width: 100%;
    outline: none;
    margin: 5px 0px 10px 0px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #363636;
    border-radius: 0px;
}

.form div button {
    width: 100%;
    border-radius: 50px;
    font-size: 18px;
}

.link {
    color: grey;
    text-decoration: none;
}

.link:hover {
    color: #f6ba42;
    text-decoration: none;
}
.right {
    height: 100%;
    text-align: center;
    background-color: #f6ba42;
}
.left {
    height: 100vh;
}
.generatedCode {
    border: #F6BA42 1px solid;
    border-radius: 20px;
    letter-spacing: 0.7cm;
    padding: 10px 20px 10px 40px;
    font-size: 50px;
}
.sendCode {
    padding: 15px 100px 15px 100px;
    border-radius: 100px;
    background-color: #3E3B4E;
    border: none;
    color: white;
    font-size: 16px;
}
.image1 {
    width: 90%;
}
.logo {
    width: 50px;
    margin: 20px 0 0 50px;
}
.logo1 {
    width: 100px;
    margin: 50px 0px;
}
.select {
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0px 50px 20px 50px;
}
.footer p {
    color: gray;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
}
.text {
    opacity: 0.7;
    font-size: 14px;
    margin-top: 30px;
}
.error {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #FF0000;
}
.enterCode, .enterCode:focus, .enterCode:active {
    border: #F6BA42 1px solid;
    border-radius: 20px;
    letter-spacing: 0.7cm;
    padding: 10px 20px 10px 20px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    outline: none !important;
    margin: 45px 0px;
}
